import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'services/dialog.service';
import { MatExpansionModule } from '@angular/material/expansion';

import { MatIconModule } from '@angular/material/icon';
import { SidebarComponent } from './sidebar.component';

@NgModule({
  imports: [RouterModule, CommonModule, TranslateModule, MatExpansionModule, MatIconModule],
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  providers: [DialogService],
})
export class SidebarModule {}
