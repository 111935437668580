import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { CentralServerService } from '@services/central-server.service';
import { inject } from '@angular/core';
import { AuthorizationService } from '@services/authorization.service';
import { ComponentService } from '@services/component.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { UserRole } from '../types/User';
import { Action, Entity } from '../types/Authorization';
import { MainFeaturesRoutesEnum } from '../types/vev-route';
import { MainFeaturesRoutes } from '../routes/main-features.routes';

const LOGIN_ROUTE = '/auth/login';

export const authenticationGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const centralServerService = inject(CentralServerService);
  const router = inject(Router);
  const authorizationService = inject(AuthorizationService);
  const componentService = inject(ComponentService);

  // If user is not authenticated redirect to login page
  if (!centralServerService.isAuthenticated()) {
    const queryParams = { returnUrl: state.url };
    await router.navigate([LOGIN_ROUTE], { queryParams });
    return false;
  }

  // If user is super admin and route is shown in super tenant
  if (route.data?.displayInSuperTenant && authorizationService.isSuperAdmin()) {
    return true;
  }

  const auth = route?.data?.auth;
  const component = route?.data?.component;

  // Route is not restricted by any permissions or components
  if (!auth && !component) {
    return true;
  }

  const redirectRoute = getDefaultRoute(authorizationService.getLoggedUser().role);
  if (component && !componentService.isActive(component)) {
    const isActiveInSuperTenant = route.data?.activeInSuperTenant;
    if (authorizationService.isSuperAdmin() && isActiveInSuperTenant) {
      return true;
    }

    await router.navigate([redirectRoute]);
    return false;
  }

  // Route is not restricted by auth
  if (!auth) {
    return true;
  }

  const navigationService = inject(NavigationService);
  const isAllowed = navigationService.isRouteAllowed(auth);
  if (isAllowed) {
    return true;
  }

  await router.navigate([redirectRoute]);

  if (redirectRoute === LOGIN_ROUTE) {
    centralServerService.clearLoginInformation();
  }
  return false;
};

const getDefaultRoute = (userRole: string): string => {
  const authorizationService = inject(AuthorizationService);
  switch (userRole) {
    case UserRole.SUPER_ADMIN:
      if (authorizationService.canAccess(Entity.TENANT, Action.LIST)) {
        return MainFeaturesRoutesEnum.TENANTS;
      }
      break;
    case UserRole.ADMIN:
    case UserRole.BASIC:
    case UserRole.DEMO:
    default:
      return getFirstAuthorizedRoute();
  }

  return LOGIN_ROUTE;
};

const getFirstAuthorizedRoute = (): string => {
  const navigationService = inject(NavigationService);
  const foundRoute = MainFeaturesRoutes.filter(
    (route) => route.path !== '' && route.path !== MainFeaturesRoutesEnum.RELEASE_NOTES,
  ).find((route) => navigationService.isRouteAllowed(route.data?.auth));

  if (foundRoute) {
    return foundRoute.path;
  }

  return LOGIN_ROUTE;
};
