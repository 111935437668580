import { ActivatedRouteSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { Action, Entity } from './Authorization';
import { TenantComponents } from './Tenant';

export enum MainFeaturesRoutesEnum {
  TENANTS = 'tenants',
  INVOICE = 'invoices',
  CHARGING_STATION = 'charging-stations',
  TEMPLATES = 'charging-station-templates',
  USERS = 'users',
  TAGS = 'tags',
  TRANSACTIONS = 'transactions',
  ORGANIZATIONS = 'organization',
  ASSETS = 'assets',
  CARS = 'cars',
  STATISTICS = 'statistics',
  SETTINGS = 'settings',
  LOGS = 'logs',
  FEATURE_TOGGLES = 'feature-toggles',
  RELEASE_NOTES = 'release-notes',
  SMART_CHARGING = 'smart-charging',
  ANALYTICS = 'analytics',
}

export type VevRoutes = VevRoute[];

export interface VevRouteData {
  breadcrumb?: string;
  breadcrumbDynamicTitle?: (snapshot: ActivatedRouteSnapshot) => Observable<string>;
  menu?: MenuData;
  auth?: RouteAuthData | RouteAuthData[];
  component?: TenantComponents;
  activeInSuperTenant?: boolean;
  displayInSuperTenant?: boolean;
}

export interface VevRoute extends Route {
  children?: VevRoute[];
  data?: VevRouteData;
}

export interface VevMainRoute extends VevRoute {
  path: MainFeaturesRoutesEnum | '';
}

interface MenuData {
  title: string;
  icon: string;
  path: string;
}

export interface RouteAuthData {
  entity: Entity;
  action: Action;
}
