import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/el';
import 'dayjs/locale/en';
import 'dayjs/locale/en-au';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';

import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs'; // ACHTUNG - cz does not exists ==> cs-CZ
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEnAU from '@angular/common/locales/en-AU';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material-dayjs';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { Loader } from '@googlemaps/js-api-loader';
import { matTabsConfigProvider } from '@providers/angular-material.provider';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MarkdownComponent, MarkdownModule } from 'ngx-markdown';
import { NavigationService } from '@services/navigation/navigation.service';
import { PlatformService } from '@services/platform.service';
import { AuthModule } from '@auth0/auth0-angular';
import { ApiModule } from './api/api.module';
import { ReleaseNoteApi } from './api/release-note/release-note.api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserNotSupportedModule } from './browser-not-supported/browser-not-supported.module';
import { CustomTranslateDefaultParser } from './i18n/custom-translate.parse';
import { I18TranslateLoader } from './i18n/i18-translate.loader';
import { httpInterceptorProviders } from './interceptors';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { MaterialModule } from './material/material.module';
import { envProvider } from './providers/env.provider';
import { WINDOW_PROVIDERS } from './providers/window.provider';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { RepositoryModule } from './repository/repository.module';
import { AuthorizationService } from './services/authorization.service';
import { CentralServerService } from './services/central-server.service';
import { ComponentService } from './services/component.service';
import { EnvironmentService } from './services/environment.service';
import { LocalStorageService } from './services/local-storage.service';
import { LocaleService } from './services/locale.service';
import { MessageService } from './services/message.service';
import { MixpanelService } from './services/mixpanel.service';
import { SentryService } from './services/sentry.service';
import { SpinnerService } from './services/spinner.service';
import { StripeService } from './services/stripe.service';
import { WindowService } from './services/window.service';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { appInitProvider } from './utils/app-factory';
import { SentryUtils } from './utils/sentry/sentry-utils';

registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localePt);
registerLocaleData(localeIt);
registerLocaleData(localeCs);
registerLocaleData(localeEnAU);
registerLocaleData(localeEl);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, ReleaseNotesComponent],
  exports: [TranslateModule, NgxGpAutocompleteModule],
  bootstrap: [AppComponent],
  imports: [
    ApiModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    BrowserNotSupportedModule,
    FooterModule,
    FormsModule,
    MarkdownModule.forRoot(),
    MarkdownComponent,
    MaterialModule,
    NavbarModule,
    NgxCaptchaModule,
    NgxDaterangepickerMd.forRoot(),
    NgxGpAutocompleteModule,
    ReactiveFormsModule,
    RepositoryModule,
    RouterModule,
    SidebarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: I18TranslateLoader,
        deps: [HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useClass: CustomTranslateDefaultParser,
      },
      defaultLanguage: 'en',
    }),
    MarkdownComponent,
    AuthModule.forRoot(),
  ],
  providers: [
    WINDOW_PROVIDERS,
    EnvironmentService,
    CentralServerService,
    AuthorizationService,
    NavigationService,
    ComponentService,
    SpinnerService,
    LocaleService,
    LocalStorageService,
    MessageService,
    TranslateService,
    WindowService,
    StripeService,
    ReleaseNoteApi,
    SentryService,
    MixpanelService,
    envProvider,
    appInitProvider,
    matTabsConfigProvider,
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyAlrUfxOfuXFDifAw3MtyxYWf36KnvWjCs',
        libraries: ['places'],
      }),
    },
    ...SentryUtils.getSentryProviders(),
    httpInterceptorProviders,
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    PlatformService,
  ],
})
export class AppModule {
  public constructor(
    private centralServerService: CentralServerService,
    private translateService: TranslateService,
    private sentryService: SentryService,
  ) {
    this.sentryService.init();

    // Default
    let language = this.translateService.getBrowserLang();
    // Get current user
    const loggedUser = this.centralServerService.getLoggedUser();
    if (loggedUser && loggedUser.language) {
      language = loggedUser.language;
    }
    // Use the browser's language or default to EN
    translateService.use(language);
  }
}
