import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ConfigEntity } from '@repository/config/entities/config.entity';
import { Env, ENV } from '@providers/env.provider';
import { EnvironmentRepositoryService } from '@repository/config/environment.repository.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public constructor(
    private configRepository: EnvironmentRepositoryService,
    @Inject(ENV) private env: Env,
  ) {}

  public init(): Observable<ConfigEntity> {
    return this.configRepository.getConfigEntity().pipe(
      tap((config) => {
        this.env.set(config);
      }),
    );
  }
}
