import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { HttpHeaders } from '../../types/HttpHeaders';

/// This interceptor add a unique id to each http request
export class CorrelationIdInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const correlationId = uuidv4();
    const clonedRequest = req.clone({
      headers: req.headers.set(HttpHeaders.CORRELATION_ID, correlationId),
    });

    return next.handle(clonedRequest);
  }
}
