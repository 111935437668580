import { MainFeaturesRoutesEnum, VevMainRoute } from '../types/vev-route';
import { Action, Entity } from '../types/Authorization';
import { TenantComponents } from '../types/Tenant';

export const SuperTenantRoutes: VevMainRoute[] = [
  {
    path: MainFeaturesRoutesEnum.TENANTS,
    loadChildren: async () => (await import('./../pages/tenants/tenants.module')).TenantsModule,
    data: {
      menu: {
        title: 'tenants',
        icon: 'account_balance',
        path: '/tenants',
      },
      auth: {
        entity: Entity.TENANT,
        action: Action.LIST,
      },
    },
  },
  {
    path: MainFeaturesRoutesEnum.FEATURE_TOGGLES,
    loadChildren: async () => (await import('./../pages/feature-toggles/feature-toggles.module')).FeatureTogglesModule,
    data: {
      menu: {
        title: 'feature_toggles',
        icon: 'add_task',
        path: '/feature-toggles',
      },
      auth: {
        entity: Entity.FEATURE_TOGGLES,
        action: Action.LIST,
      },
    },
  },
  {
    path: MainFeaturesRoutesEnum.TEMPLATES,
    loadChildren: async () =>
      (await import('./../pages/charging-station-templates/charging-station-templates.module'))
        .ChargingStationTemplatesModule,
    data: {
      menu: {
        title: 'charging_station_templates',
        icon: 'assignment',
        path: '/charging-station-templates',
      },
      auth: {
        entity: Entity.CHARGING_STATION_TEMPLATE,
        action: Action.LIST,
      },
      activeInSuperTenant: true,
      displayInSuperTenant: true,
      component: TenantComponents.CHARGING_STATION_TEMPLATE,
    },
  },
];
