// http-error.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  private handleHttpError(error: HttpErrorResponse): Observable<any> {
    const errorInfo = { status: 0, message: '', details: null };
    // Handle redirection of Tenant
    if (error.status === StatusCodes.MOVED_TEMPORARILY && error.error.size > 0) {
      return new Observable((observer) => {
        const reader = new FileReader();
        reader.readAsText(error.error); // convert blob to Text
        reader.onloadend = () => {
          errorInfo.status = error.status;
          errorInfo.message = error.message;
          errorInfo.details = JSON.parse(reader.result.toString());
          observer.error(errorInfo);
        };
      });
    }
    if (error instanceof TimeoutError) {
      errorInfo.status = StatusCodes.REQUEST_TIMEOUT;
      errorInfo.message = error.message;
      errorInfo.details = null;
    } else {
      errorInfo.status = error.status;
      errorInfo.message = error.message ?? error.toString();
      errorInfo.details = error.error ?? null;
    }
    return throwError(() => errorInfo);
  }
}
