import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { Utils } from '../utils/Utils';

@Injectable()
export class CustomTranslateDefaultParser extends TranslateDefaultParser {
  public getValue(target: any, key: string): any {
    let value = super.getValue(target, key);

    if (!Utils.isEmptyArray(value)) {
      value = (value as string[]).join('<br>');
    }
    return value;
  }
}
