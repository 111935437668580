import { forkJoin, Observable, of, tap } from 'rxjs';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { CentralServerService } from '../services/central-server.service';
import { EnvironmentService } from '../services/environment.service';
import { MixpanelService } from '../services/mixpanel.service';

const appFactory =
  (
    centralServerService: CentralServerService,
    environmentService: EnvironmentService,
    mixpanelService: MixpanelService,
    authConfig: AuthClientConfig,
  ): (() => Observable<any>) =>
  () =>
    forkJoin([
      environmentService.init().pipe(
        tap((config) => {
          mixpanelService.init();
          authConfig.set(config.auth0Config);
        }),
      ),
      of(centralServerService.initUserToken()),
    ]);

export const appInitProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: appFactory,
  deps: [CentralServerService, EnvironmentService, MixpanelService, AuthClientConfig],
  multi: true,
};
