import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { ENV } from '../providers/env.provider';
import { TenantHeaderInterceptor } from './tenant-header/tenant-header.interceptor';
import { AuthenticationInterceptor } from './authentication/authentication.interceptor';
import { ContentTypeInterceptor } from './content-type/content-type.interceptor';
import { CorrelationIdInterceptor } from './correlation-id/correlation-id.interceptor';
import { HttpErrorInterceptor } from './http-error/http-error.interceptor';
import { BaseUrlInterceptor } from './base-url/base-url.interceptor';
import { SentryBreadcrumbInterceptor } from './sentry/sentry-breadcrumb.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: TenantHeaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CorrelationIdInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true, deps: [ENV] },
  { provide: HTTP_INTERCEPTORS, useClass: SentryBreadcrumbInterceptor, multi: true },
];
