import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '../../types/HttpHeaders';

export class SentryBreadcrumbInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((response: HttpResponse<any>) => {
        // Add a breadcrumb for the request
        this.addResponseToBreadcrumb(response.status ?? 200, response.body, req);
      }),
      catchError((err: HttpErrorResponse) => {
        this.addResponseToBreadcrumb(err.status, err.error, req);

        throw err;
      }),
    );
  }

  private addResponseToBreadcrumb(status: number, response: any, req: HttpRequest<any>): void {
    Sentry.addBreadcrumb({
      category: 'xhr',
      type: 'http',
      level: status >= 400 ? 'error' : 'info',
      message: `${req.method} ${req.url} [${status}]`,
      data: {
        url: req.url,
        method: req.method,
        status_code: status,
        correlationId: req.headers.get(HttpHeaders.CORRELATION_ID),
        body: req.body,
        response,
      },
    });
  }
}
