import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RESTServerRoute } from '../../types/Server';

@Injectable()
export class ReleaseNoteApi {
  public constructor(private httpClient: HttpClient) {}

  public getReleaseNote(): Observable<string> {
    return this.httpClient.get(RESTServerRoute.REST_RELEASE_NOTES).pipe(map((response: any) => response.releaseNotes));
  }
}
