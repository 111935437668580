import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CentralServerService } from '../../services/central-server.service';
import { WindowService } from '../../services/window.service';
import { HttpHeaders as HttpHeadersEnum } from '../../types/HttpHeaders';

@Injectable()
export class TenantHeaderInterceptor implements HttpInterceptor {
  private static SUPER_TENANT_SUBDOMAIN = 'default';

  public constructor(
    private readonly windowsService: WindowService,
    private readonly centralService: CentralServerService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const updatedRequest = req.clone({
      headers: this.updateHttpHeaders(req.headers),
    });

    return next.handle(updatedRequest);
  }

  private updateHttpHeaders(headers: HttpHeaders): HttpHeaders {
    const headersWithSubDomain = headers.append(HttpHeadersEnum.TENANT_SUB_DOMAIN, this.getSubDomain());
    const tenantId = this.getTenantId();

    if (!tenantId) {
      return headersWithSubDomain;
    }

    return headersWithSubDomain.set(HttpHeadersEnum.TENANT_ID, tenantId);
  }

  private getSubDomain(): string {
    const subDomainInUrl = this.windowsService.getSubdomain();
    return subDomainInUrl.length ? subDomainInUrl : TenantHeaderInterceptor.SUPER_TENANT_SUBDOMAIN;
  }

  private getTenantId(): string | undefined {
    return this.centralService.getLoggedUser()?.tenantID;
  }
}
