import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { SentryUtils } from './app/utils/sentry/sentry-utils';

if (typeof document !== 'undefined') {
  // Bootstrap can only be loaded in the browser and not server-side
  import('bootstrap')
    .then(() => import('bootstrap-notify'))
    .catch((err) => {
      console.error('Error loading Bootstrap:', err);
    });
}
SentryUtils.init();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
