import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { WINDOW } from '@providers/window.provider';

export const unsupportedBrowserGuard: CanActivateFn = async () => {
  const window = inject(WINDOW);
  const router = inject(Router);

  const isIE = /msie\s|trident\//i.test(window?.navigator.userAgent);
  if (isIE) {
    await router.navigate(['browser-not-supported']);
    return false;
  }
};
