import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { unsupportedBrowserGuard } from '@shared/guards/unsupported-browser.guard';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { authenticationGuard } from './guard/authentication.guard';
import { MainFeaturesRoutesEnum, VevRoutes } from './types/vev-route';
import { MainFeaturesRoutes } from './routes/main-features.routes';
import { SuperTenantRoutes } from './routes/super-tenant.routes';

const routes: VevRoutes = [
  {
    path: '',
    canActivate: [unsupportedBrowserGuard],
    children: [
      {
        path: 'auth',
        loadChildren: async () => (await import('./pages/authentication/authentication.module')).AuthenticationModule,
      },
      {
        path: 'scan-pay',
        component: AuthLayoutComponent,
        loadChildren: async () => (await import('./scan-and-pay/scan-and-pay.module')).ScanAndPayModule,
      },
      {
        path: 'verify-email',
        redirectTo: 'auth/verify-email',
        pathMatch: 'full',
      },
      {
        path: 'define-password',
        redirectTo: 'auth/define-password',
        pathMatch: 'full',
      },
      {
        path: '',
        canActivateChild: [authenticationGuard],
        component: AdminLayoutComponent,
        children: [...SuperTenantRoutes, ...MainFeaturesRoutes],
      },
      {
        path: 'browser-not-supported',
        component: BrowserNotSupportedComponent,
      },
      {
        path: '**',
        redirectTo: MainFeaturesRoutesEnum.CHARGING_STATION,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
