import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CentralServerService } from '@services/central-server.service';
import { HttpHeaders } from '../../types/HttpHeaders';
import { RESTServerRoute } from '../../types/Server';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  public constructor(private readonly centralService: CentralServerService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.centralService.getLoggedUserToken() || req.url.includes(RESTServerRoute.REST_SSO_SIGNIN)) {
      return next.handle(req);
    }

    const bearerToken = `Bearer ${this.centralService.getLoggedUserToken()}`;
    const clonedRequest = req.clone({
      headers: req.headers.set(HttpHeaders.AUTHORIZATION, bearerToken),
    });

    return next.handle(clonedRequest);
  }
}
