import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '../../types/HttpHeaders';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
  private static readonly CHANGE_LOGIN_IMAGE_ENDS_URL = '/background-image';

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith(ContentTypeInterceptor.CHANGE_LOGIN_IMAGE_ENDS_URL)) {
      return next.handle(req);
    }

    const clonedRequest = req.clone({
      headers: req.headers.set(HttpHeaders.CONTENT_TYPE, 'application/json'),
    });

    return next.handle(clonedRequest);
  }
}
